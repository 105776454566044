import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-20px);
    }
    100% {
      transform: translatey(0px);
    }
  }

  .x-icon {
    position: absolute;
    z-index: 1;

    width: 134px;
    height: 134px;
    top: 260px;
    left: 30px;
    transform: translatey(0px);
    animation: float 5.5s ease-in-out infinite;
    
    @media ${device.tablet} {
      left: 410px;
      bottom: 0;
      width: 163px;
      height: 163px;
    }

    @media ${device.desktopLG} {
      width: 225px;
      height: 225px;
      left: 590px;
      bottom: 0;
    }
    
    @media ${device.desktopXL} {
      width: 318px;
      height: 318px;
      left: 775px;
      bottom: 0;
    }

    @media ${device.desktopXXXL} {
      width: 318px;
      height: 318px;
      left: 890px;
      top: 365px;
    }

    @media (min-width: 1920px) {
      width: 318px;
      height: 318px;
      left: 1020px;
      top: 365px;
    }
  }
`
