import React from 'react'

import Layout from 'src/layouts/BaseLayout'

import Hero from './sections/hero/_index'
import DinheiroParado from './sections/dinheiro-parado/_index'
import Cdb from './sections/cdb/_index'
import SeuNomeLimpo from './sections/seu-nome-limpo/_index'
import VoceTemImovel from './sections/voce-tem-imove/_index'

import pageContext from './pageContext.json'
import { Wrapper } from './style'

const SemanaIndependenciaFinanceira = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero />
        <DinheiroParado />
        <VoceTemImovel />
        <Cdb />
        <SeuNomeLimpo />
      </Layout>
    </Wrapper>
  )
}

export default SemanaIndependenciaFinanceira
