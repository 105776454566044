import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'
import styled from 'styled-components'

import HeroBgXl from '../../assets/images/hero-bg-desktop-lg.png'
import HeroTablet from '../../assets/images/semana-hero-bg-tablet.png'

export const HeroContainer = styled.section`
  min-height: calc(100vh - 64px);
  display: flex; 
  align-items: center;
  justify-content: center;

  @media ${device.tablet} {
    min-height: 460px;
    background-image: url(${HeroTablet});
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;

  }
  @media ${device.desktopLG} {
    min-height: 585px;
  }

  @media ${device.desktopXL} {
    min-height: 756px;
    background-image: url(${HeroBgXl});
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;
  }
`

export const Tag = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${orange.extra};
  padding: 0 16px;
  max-width: 362px;
  height: 42px;
  border-radius: 14px;
  margin-bottom: 16px;

  @media ${device.desktopLG} {
    max-width: 300px;
  }

  @media ${device.desktopXL} {
    max-width: 340px;
  }
`
