import React from 'react'
import Img from 'gatsby-image'
import usePageQuery from '../../pageQuery'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import ArrowIcon from '../../assets/images/icon-arrow.png'

import { Section } from './style'

const SeuNomeLimpo = () => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='bg-orange--extra'>
      <div className='container py-5'>
        <img className='arrow-icon' src={ArrowIcon} alt='Icon Arrow' />

        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-lg-5'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-white fs-600 mb-4'>Seu nome limpo sem pesar no bolso</h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-white mb-4'>Só na Semana da Independência Financeira, você conta com condições exclusivas para quitar suas dívidas.<br /><br />Parcele em até 24 vezes sua dívida do cartão de crédito, com descontos especiais para pagamento à vista ou em até 3 vezes. </p>
            <a
              href='https://negocie.bancointer.com.br/login?utm_source=banco_inter_landing&_gl=1*1felwt1*_gcl_aw*R0NMLjE2NjAzMDkyNjUuRUFJYUlRb2JDaE1JajZ5X3RaU3QtUUlWQXVGY0NoMHZPZ0NGRUFBWUFTQUFFZ0xMZ19EX0J3RQ..&_ga=2.183271524.394915549.1662383422-1893266447.1659547720&_gac=1.216728098.1660309265.EAIaIQobChMIj6y_tZSt-QIVAuFcCh0vOgCFEAAYASAAEgLLg_D_BwE'
              target='__blank'
              className='btn btn-white btn--lg rounded-2 fs-14 fw-600 text-orange--extra text-none'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_05',
                  element_action: 'click button',
                  element_name: 'Quero saber mais',
                  section_name: 'Seu nome limpo sem pesar no bolso',
                  redirect_url: 'https://negocie.bancointer.com.br/login?utm_source=banco_inter_landing&_gl=1*1felwt1*_gcl_aw*R0NMLjE2NjAzMDkyNjUuRUFJYUlRb2JDaE1JajZ5X3RaU3QtUUlWQXVGY0NoMHZPZ0NGRUFBWUFTQUFFZ0xMZ19EX0J3RQ..&_ga=2.183271524.394915549.1662383422-1893266447.1659547720&_gac=1.216728098.1660309265.EAIaIQobChMIj6y_tZSt-QIVAuFcCh0vOgCFEAAYASAAEgLLg_D_BwE',
                })
              }}
            >
              Quero saber mais
            </a>
          </div>
          <div className='col-12 col-md-6 col-lg-5 order-first order-md-last mb-4 mb-md-0'>
            <Img fluid={data.seuNomeLimpoImage.fluid} alt='Uma mulher sorrindo acessando seu notebook' />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default SeuNomeLimpo
