import React from 'react'
import Img from 'gatsby-image'
import usePageQuery from '../../pageQuery'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import IconMoney from '../../assets/images/icon-money.png'
import EqualIcon from '../../assets/images/equal-icon.png'
import { CdbSection } from './styles'

const Cdb = () => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <CdbSection className='bg-grayscale--100'>
      <div className='container py-5 py-md-3 pb-md-d5'>
        <img className='money-icon' src={IconMoney} alt='Símbolo moeda' />
        <img className='equal-icon' src={EqualIcon} alt='Sinal de igualdade' />
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-lg-5 mb-4 mb-md-0'>
            <Img fluid={data.cdbMaisLimite.fluid} alt='Uma mulher sorrindo, com o cartão do Inter na mão, olhando ao seu celular' />
          </div>
          <div className='col-12 col-md-6 col-lg-5'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 mb-4'>CDB + Limite: cartão de crédito para todos</h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500 mb-4'>E se você pudesse usar seus investimentos para garantir ou aumentar seu limite de crédito? Investindo a partir de R$ 100, o seu dinheiro rende e 100% do valor é convertido em limite de crédito.</p>
            <a
              href='https://blog.inter.co/cdb-mais-limite-de-credito-inter?utm_source=crm&utm_medium=email&utm_campaign=20220902_Cartao_Cobranca_SemanaIndependencia_CDBMaisLimite&af_xp=custom&af_force_deeplink=true&pid=lp&is_retargeting=true&af_click_lookback=15d&shortlink=s6zpmnc9&deep_link_value=bancointer%3A%2F%2Finvestimentos&af_channel=lp&c=20220902_Cartao_Cobranca_SemanaIndependencia_CDBMaisLimite'
              target='__blank'
              className='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-600 text-none'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_04',
                  element_action: 'click button',
                  element_name: 'Quero limite de crédito',
                  section_name: 'CDB + Limite: cartão de crédito para todos',
                  redirect_url: 'https://blog.inter.co/cdb-mais-limite-de-credito-inter?utm_source=crm&utm_medium=email&utm_campaign=20220902_Cartao_Cobranca_SemanaIndependencia_CDBMaisLimite&af_xp=custom&af_force_deeplink=true&pid=lp&is_retargeting=true&af_click_lookback=15d&shortlink=s6zpmnc9&deep_link_value=bancointer%3A%2F%2Finvestimentos&af_channel=lp&c=20220902_Cartao_Cobranca_SemanaIndependencia_CDBMaisLimite',
                })
              }}
            >
              Quero limite de crédito
            </a>
          </div>
        </div>
      </div>
    </CdbSection>
  )
}

export default Cdb
