import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-20px);
    }
    100% {
      transform: translatey(0px); 
    }
  }

  .arrow-icon {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    transform: translatey(0px);
    animation: float 5.5s ease-in-out infinite;
    width: 127px;
    height: 127px;

    @media ${device.tablet} {
      top: -70px;
      width: 164px;
      height: 164px;
    }

    @media ${device.desktopLG} {
      top: -110px;
      width: 232px;
      height: 232px;
    }

    @media ${device.desktopXL} {
      top: -180px;
      width: 365px;
      height: 365px;
    }
  }
`
