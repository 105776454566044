import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const DinheiroParadoSection = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @media (min-width: 1920px) {
    height: 600px;
  }

  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-20px);
    }
    100% {
      transform: translatey(0px);
    }
  }

  @keyframes floatDollar {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-5px);
    }
    100% {
      transform: translatey(0px);
    }
  }

  .dollar-right {
    z-index: 1;
    position: absolute;
    top: -10px;
    right: -20px;
    height: 140px;
    width: 140px;
    transform: translatey(0px);
    animation: floatDollar 4.5s ease-in-out infinite;

    @media ${device.tablet} {
      top: -10px;
      left: 274px;
      width: 136px;
      height: 136px;
    }

    @media ${device.desktopLG} {
      width: 206px;
      height: 206px;
      top: -10px;
      left: 380px;
    }

    @media ${device.desktopXL} {
      height: 300px;
      width: 300px;
      top: -40px;
      left: 460px;
    }

    @media ${device.desktopXXXL} {
      height: 300px;
      width: 300px;
      top: -40px;
      left: 460px;
    }

    @media (min-width: 1920px) {
      height: 300px;
      width: 300px;
      top: -40px;
      left: 560px;
    }

    @media (min-width: 2560px) {
      left: 880px;
    }
  }

  .graph-left {
    z-index: 1;
    position: absolute;
    top: 260px;
    left: -10px;
    height: 114px;
    width: 114px;
    transform: translatey(0px);
    animation: float 6s ease-in-out infinite;

    @media ${device.tablet} {
      top: 260px;
      left: -10px;
      height: 127px;
      width: 127px;
    }

    @media ${device.desktopLG} {
      height: 184px;
      width: 184px;
      top: 380px;
      left: -10px;
    }

    @media ${device.desktopXL} {
      height: 272px;
      width: 272px;
      top: 390px;
      left: 0;
    }

    @media ${device.desktopXXXL} {
      top: 360px;
      left: 0;
    }

    @media (min-width: 1920px) {
      top: 360px;
      left: 70px;
    }

    @media (min-width: 2560px) {
      left: 420px;
    }
  }
`
