import React from 'react'
import Img from 'gatsby-image'
import usePageQuery from '../../pageQuery'
import { DinheiroParadoSection } from './styles'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import DollarRight from '../../assets/images/dollar-right.png'
import GraphLeft from '../../assets/images/graph-left.png'

const DinheiroParado = () => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <DinheiroParadoSection className='bg-orange--extra'>
      <div className='container py-4 py-lg-5'>
        <img className='dollar-right' src={DollarRight} alt='Símbolo moeda' />
        <img className='graph-left' src={GraphLeft} alt='Icon gráfico' />
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-lg-6 mb-4 mb-md-0'>
            <Img fluid={data.DinheiroParado.fluid} alt='Um rapaz sorrindo olhando para seu celular' />
          </div>
          <div className='col-12 col-md-6 col-lg-6 col-xl-5'>
            <h2 className='text-white fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 mb-3'>Dinheiro parado? Movimente seu FGTS</h2>
            <p className='text-white fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 fw-400'>
              Sabia que você pode antecipar até 10 parcelas anuais do seu Saque-Aniversário do FGTS? É dinheiro na mão para fazer
              o que você quiser: pagar a fatura do cartão de crédito, quitar uma dívida ou até mesmo para começar a investir em
              uma reserva de emergência. <br /><br />Você recebe o valor na sua conta, sem sair de casa. E o melhor: sem cobranças mensais!
            </p>
            <a
              href='https://inter.co/pra-voce/emprestimos/antecipacao-fgts/?&utm_source=landingpage&utm_campaign=indfinanceira&utm_medium=crm&utm_content=indfinanceira'
              target='__blank'
              className='btn btn-white btn--lg rounded-2 fs-14 fw-600 text-orange--extra text-none mt-4'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_02',
                  element_action: 'click button',
                  element_name: 'Quero antecipar meu FGTS',
                  section_name: 'Dinheiro parado? Movimente seu FGTS',
                  redirect_url: 'https://inter.co/pra-voce/emprestimos/antecipacao-fgts/?&utm_source=landingpage&utm_campaign=indfinanceira&utm_medium=crm&utm_content=indfinanceira',
                })
              }}
            >
              Quero antecipar meu FGTS
            </a>
          </div>
        </div>
      </div>
    </DinheiroParadoSection>
  )
}

export default DinheiroParado
