import React from 'react'
import Img from 'gatsby-image'
import usePageQuery from '../../pageQuery'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section } from './styles'

import XIcon from '../../assets/images/x-icon.png'

const VoceTemImovel = () => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5'>
      <img className='x-icon' src={XIcon} alt='Icon X' />
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-lg-5'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 mb-4'>Você tem um imóvel?</h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500 mb-4'>Conheça o Empréstimo com Garantia de Imóvel. Sem precisar vender ou sair de casa, você usa o seu imóvel como garantia para conseguir aquele dinheiro que precisa para tirar seus planos do papel.<br /><br />Diferente de outras modalidades de crédito, com esse você garante as menores taxas de juros do mercado com parcelas a partir de R$622,38. </p>
            <a
              href='https://imobiliario.inter.co/home-equity/?utm_source=landingpage&utm_medium=crm&utm_campaign=campanhaindfinanceira&utm_content=economizarsimples'
              target='__blank'
              className='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-600 text-none'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_03',
                  element_action: 'click button',
                  element_name: 'Quero simular',
                  section_name: 'Você tem um imóvel?',
                  redirect_url: 'https://imobiliario.inter.co/home-equity/?utm_source=landingpage&utm_medium=crm&utm_campaign=campanhaindfinanceira&utm_content=economizarsimples',
                })
              }}
            >
              Quero simular
            </a>
          </div>
          <div className='col-12 col-md-6 col-lg-5 order-first order-md-last mb-4 mb-md-0'>
            <Img fluid={data.voceTemImovel.fluid} alt='Imagem simbolizando um Empréstimo com Garantia de Imóvel' />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default VoceTemImovel
