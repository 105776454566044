import React from 'react'
import Img from 'gatsby-image'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Tag, HeroContainer } from './styles'
import usePageQuery from '../../pageQuery'

const Hero = () => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <HeroContainer className='bg-white'>
      <div className='container pt-3 pb-5 py-md-5'>
        <div className='row'>
          <div className='col-12 mb-4 d-md-none'>
            <Img fluid={data.semanaHeroMobile.fluid} alt='Uma mulher sorrindo com seu celular na mão' />
          </div>
          <div className='col-12 col-md-6 col-xl-5'>
            <Tag
              target='_blank'
              href='https://cloud.comunicacao.bancointer.com.br/semana-da-independencia-financeira?utm_source=crm&utm_medium=lp&utm_campaign=semanaindependenciainter&_ga=2.97292442.445181223.1662481546-20693214.1660314705'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_01',
                  element_action: 'click button',
                  element_name: 'Semana da Independência Financeira',
                  section_name: 'Economizar pode ser simples',
                  redirect_url: 'https://cloud.comunicacao.bancointer.com.br/semana-da-independencia-financeira?utm_source=crm&utm_medium=lp&utm_campaign=semanaindependenciainter&_ga=2.97292442.445181223.1662481546-20693214.1660314705',
                })
              }}
            >
              <p className='fs-14 lh-17 fs-xl-16 lh-xl-20 mb-0 text-orange--extra'>Semana da <strong>Independência Financeira</strong></p>
            </Tag>
            <h1 className='fs-24 lh-30 fs-md-24 lh-md-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mb-4'>Economizar pode ser simples</h1>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500 mb-4'>Pra sair do aperto, pra ter um dinheiro extra ou pra cuidar das suas finanças. No Inter, sua economia não fica só na conta digital sem tarifas. <br /><br />Preparamos uma semana inteira com dicas e soluções pra te ajudar a economizar e ver o seu dinheiro render.</p>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 fs-xl-20 lh-xl-25 text-grayscale--500 mb-3'><strong>De 5 a 10 de setembro, em todos os canais do Inter.</strong></p>
            <a
              href='https://cloud.comunicacao.bancointer.com.br/semana-da-independencia-financeira?utm_source=crm&utm_medium=lp&utm_campaign=semanaindependenciainter'
              target='__blank'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_01',
                  element_action: 'click button',
                  element_name: 'Quero aprender a economizar',
                  section_name: 'Economizar pode ser simples',
                  redirect_url: 'https://cloud.comunicacao.bancointer.com.br/semana-da-independencia-financeira?utm_source=crm&utm_medium=lp&utm_campaign=semanaindependenciainter&_ga=2.97292442.445181223.1662481546-20693214.1660314705',
                })
              }}
              className='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-700 text-none mt-4 mt-mb-2 mt-lg-4'
            >Quero aprender a economizar
            </a>
          </div>
        </div>
      </div>
    </HeroContainer>
  )
}

export default Hero
