import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const CdbSection = styled.section`
  position: relative;

  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-20px);
    }
    100% {
      transform: translatey(0px);
    }
  }


  .money-icon {
    height: 117px;
    width: 117px;
    position: absolute;
    top: 314px;
    left: 0;
    z-index: 1;
    transform: translatey(0px);
    animation: float 6s ease-in-out infinite;

    @media ${device.tablet} {
      height: 178px;
      width: 178px;
      top: 290px;
    }

    @media ${device.desktopLG} {
      height: 251px;
      width: 251px;
      left: -60px;
    }

    @media ${device.desktopXL} {
      height: 360px;
      width: 360px;
    }
  }

  .equal-icon {
    height: 132px;
    width: 132px;
    position: absolute;
    z-index: 1;
    top: -14px;
    right: 0;
    transform: translatey(0px);
    animation: float 6.5s ease-in-out infinite;

    @media ${device.tablet} {
      height: 156px;
      width: 156px;
      top: -40px;
      left: 280px;
    }

    @media ${device.desktopLG} {
      height: 214px;
      width: 214px;
      top: -60px;
      left: 310px;
    }

    @media ${device.desktopXL} {
      height: 330px;
      width: 330px;
      top: -120px;
      left: 440px;
    }
    
    @media ${device.desktopXXXL} {
      height: 330px;
      width: 330px;
      top: -120px;
      left: 390px;
    }

    @media (min-width: 1920px) {
      height: 330px;
      width: 330px;
      top: -120px;
      left: 530px;
    }
  }
`
