import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      DinheiroParado: imageSharp(fluid: {originalName: { regex: "/dinheiro-parado/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      voceTemImovel: imageSharp(fluid: {originalName: { regex: "/voce-tem-imovel/" }}) {
        fluid(maxWidth: 729, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      cdbMaisLimite: imageSharp(fluid: {originalName: { regex: "/cdb-mais-limite/" }}) {
        fluid(maxWidth: 729, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      } 
      seuNomeLimpoImage: imageSharp(fluid: {originalName: { regex: "/seu-nome-limpo-image/" }}) {
        fluid(maxWidth: 729, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      } 
      iconMoney: imageSharp(fluid: {originalName: { regex: "/icon-money/" }}) {
        fluid(maxWidth: 729, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      semanaHeroMobile: imageSharp(fluid: {originalName: { regex: "/semana-hero-mobile/" }}) {
        fluid(maxWidth: 729, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      } 
    }
  `)

  return pageQuery
}

export default PageQuery
